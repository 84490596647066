.search-control-wrap {
    --icon-width: 26px;
    --icon-height: 26px;
    --active-height: 40px;
    --close-button-max-size: 12px;
    --icon-button-max-size: 18px;
    --primary-color: #000000;
    --secondary-color: rgba(141, 141, 141, 0.639);
    --border-color: rgba(0, 0, 0, 0.2);
    --border-size: 0px;
    --main-background-color: #ffffff;
    --background-color-candidate: #5a6673;
    --background-color-hover: #5a6673b3;
    --background-color-active: #50c3bd;
    --svg-stroke-width: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    position: relative;
    z-index: 401;
    color: var(--primary-color);
    display: inline-grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    border: var(--border-size) solid var(--border-color);
    border-radius: 4px;
}

.search-control-wrap ::placeholder {
    color: var(--secondary-color);
    opacity: 1;
}

.search-control {
    /* width: 100%; */
    position: relative;
    height: 100%;
    text-align: center;
    font: bold 12px/20px Tahoma, Verdana, sans-serif;
    background-color: var(--main-background-color);
    box-sizing: border-box;
    background-clip: padding-box;
    cursor: default;
    border-radius: 4px;
    display: flex;
    z-index: 802;
    box-shadow: none !important;
}

.search-control-icon-button {
    position: relative;
    background-color: transparent;
    padding: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: unset;
    border-radius: 4px;
    border: 0 !important;
    height: var(--icon-height) !important;
    width: var(--icon-width);
    line-height: 30px;
    z-index: 0;
    cursor: pointer;
    transition: height 0.1s linear 0s, border-top-right-radius 0s linear 0.1s, border-bottom-right-radius 0s linear 0.1s;
}

.search-control-input {
    position: relative;
    background-color: var(--main-background-color);
    z-index: 50;
    outline: 0;
    padding: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    font-size: 14px;
    border: 0;
    height: var(--icon-height);
    color: inherit;
    box-sizing: border-box;
    width: 0;
    transition: width 0.1s linear 0s, height 0.1s linear 0s, padding 0.1s linear 0s;
}

.search-control-close-button {
    display: none;
    stroke: #f2f2f2;
    transform-origin: center;
    transform: scale(1);
    outline: unset;
    border: unset;
    padding: unset;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.search-control-close-button-active {
    display: flex;
}

.search-control-active .search-control-icon-button {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    height: var(--active-height) !important;
    transition: height 0.1s linear 0s;
}

.search-control-active .search-control-input {
    padding: 0px 26px 0px 0px;
    height: var(--active-height);
    width: 244px;
}

.search-control-active .search-control-close-button {
    background-color: transparent;
    height: var(--active-height);
    width: 26px;
    font: normal 18px / calc(var(--active-height) - 2px) "Lucida Console", Monaco, monospace;
    right: 0;
    color: inherit;
    cursor: pointer;
    z-index: 51;
    position: absolute;
}
.search-control-icon-button svg,
.search-control-active .search-control-close-button > svg {
    height: 75%;
    width: 75%;
    transform-origin: center;
    stroke-width: var(--svg-stroke-width);
    stroke: var(--primary-color);
}
.search-control-icon-button svg {
    max-height: var(--icon-button-max-size);
    max-width: var(--icon-button-max-size);
}
.search-control-active .search-control-close-button > svg {
    max-height: var(--close-button-max-size);
    max-width: var(--close-button-max-size);
}

/* Select */
.search-control-info-wrapper {
    width: 100%;
    height: auto;
    position: absolute;
    top: 100%;
    box-sizing: border-box;
    padding: 0px 0 0 0;
    margin: 7px 0 0 0;
    overflow-y: auto;
    z-index: 9999;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 4px;
}

.search-control-info-wrapper-close {
    display: none;
}

.search-control-info {
    height: auto;
    display: flex;
    position: relative;
    background-color: var(--main-background-color);
    padding: 0;
    border: var(--border-size) solid var(--border-color);
    border-radius: 4px;
    text-align: center;
    overflow-y: auto;
    color: inherit;
}

.search-control-info-span {
    margin: 0 auto;
    font-weight: normal;
    font-size: 12px;
}

.search-control-info-list {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background: transparent;
    height: 100%;
    outline: none;
}

.search-control-info-list:focus .search-control-info-list-item.candidate,
.search-control-info-list-item:active,
.search-control-info-list-item:focus {
    background: var(--background-color-active) !important;
}
.search-control-info-list:focus .search-control-info-list-item:not(.active).candidate,
.search-control-info-list-item:not(.active):active,
.search-control-info-list-item:not(.active):focus {
    background: var(--background-color-candidate) !important;
}
.search-control-info-list-item {
    border-bottom: 1px solid var(--border-color);
    font: normal 12px/16px Tahoma, Verdana, sans-serif;
    display: block;
    list-style: none;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    /* align-items: center; */
    /* display: flex; */
    color: inherit;
    white-space: pre-wrap;
}

.search-control-info-list-item:last-child,
.search-control-info-list-item:hover:last-child {
    border-bottom: none;
}

.search-control-info-list-item.active,
.search-control-info-list-item.active:hover {
    background-color: var(--background-color-active);
}

.search-control-info-list-item:hover {
    background-color: var(--background-color-hover);
}

.search-control-info-list-item:hover p,
.search-control-info-list-item.active p {
    margin: 0;
}

.search-control-info-list-item p,
.search-control-info-list-item p {
    margin: 0;
}

/* popup */
.search-control-popup-seperator {
    width: 100%;
    height: 1px;
    background-color: #eee;
}
